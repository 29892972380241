import APIService from './APIService';

export default {
  getPeriodoIncrementalSelected(id) {
    return APIService.apiCall().get(`periodos-incrementais/${id}`);
  },

  save(postData) {
    return APIService.apiCall().post(
      '/periodos-incrementais/save',
      JSON.stringify(postData)
    );
  }
};
