<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-calendar-blank"
          inline
          class="px-5 py-3"
          style="min-height: 300px"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light">
              Período Incremental :: {{ company.name }}
            </div>
          </template>
          <v-row class="mt-5">
            <v-col cols="12">
              <v-alert
                dense
                outlined
                type="info"
                color="blue"
              >
                Aqui você pode informar ao sistema qual é o período incremental
                da {{ company.name }}.
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <input-date
                label="Data Inicial"
                :selectedDate="dataInicio"
                :rules="{ required: true, min: 10 }"
                @dataSelected="(data) => (dataInicio = data)"
              />
            </v-col>
            <v-col cols="6">
              <input-date
                label="Data Final"
                :selectedDate="dataFim"
                :rules="{ required: true, min: 10 }"
                @dataSelected="(data) => (dataFim = data)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                color="primary"
                min-width="100"
                style="float: right"
                class="mt-n5 mr-0"
                @click="save()"
                :disabled="btnSaveDisabled"
                :loading="loading"
              >
                Salvar
              </v-btn>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CompanyService from '@/services/CompanyService';
import PeriodosIncrementaisService from '@/services/PeriodosIncrementaisService';
import routes from '@/store/modules/routes';

export default {
  name: 'PeriodosIncrementais',

  components: {
    InputDate: () => import('@/components/general/InputDate.vue')
  },

  data: () => ({
    company: { name: '' },
    dataInicio: '',
    dataFim: '',
    loading: false
  }),

  mounted() {
    this.getCompany(this.$route.params.id);
    this.getPeriodosIncrementais(this.$route.params.id);
  },

  computed: {
    btnSaveDisabled() {
      return !this.dataInicio || !this.dataFim ? true : false;
    }
  },

  methods: {
    getPeriodosIncrementais(id) {
      PeriodosIncrementaisService.getPeriodoIncrementalSelected(id)
        .then((response) => {
          this.dataInicio = response.data.data_inicio;
          this.dataFim = response.data.data_fim;
        })
        .catch(() =>
          this.$toast.error(
            'Erro ao buscar os dados dos períodos incrementais.',
            '',
            { position: 'topRight' }
          )
        );
    },

    getCompany(id) {
      CompanyService.getCompanyById(id)
        .then((response) => {
          this.company.name = response.name;
        })
        .catch(() =>
          this.$toast.error('Erro ao buscar os dados da empresa.', '', {
            position: 'topRight'
          })
        );
    },

    save() {
      this.loading = true;

      const data = {
        empresa: this.$route.params.id,
        data_inicio: this.dataInicio,
        data_fim: this.dataFim
      };

      PeriodosIncrementaisService.save(data)
        .then(() => {
          this.$toast.success(
            'Períodos incrementais configurados com sucesso.',
            '',
            { position: 'topRight' }
          );
          this.$router.replace({
            name: routes.state.appRoutes['EMPRESAS_LISTAR']
          });
        })
        .catch(() =>
          this.$toast.error(
            'Erro ao salvar o período incremental para a empresa.',
            '',
            { position: 'topRight' }
          )
        )
        .finally(() => (this.loading = false));
    }
  }
};
</script>
